import {
  useContext,
  ThemeContext,
  RenderSchemaContext,
} from '~/src/core/contexts'

const Layout = ({ metadata, intro, contact, contactInfos, children }) => {
  const {
    units: { cell },
    scales: { scale },
    cssHelpers: { center },
    fluid,
    defaults: {
      styles: { mainFrameWrapper, main: mainFromThmeme, boxed },
    },
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  const frame = {
    ...fluid.locks([
      {
        properties: ['paddingLeft', 'paddingRight'],
        min: 0,
        max: scale(10),
        unit: 'em',
      },
    ]),
    ...mainFrameWrapper,
    // backgroundColor: bg,
    // position: 'relative',
    // zIndex: -100000,
  }

  const main = {
    // backgroundColor: 'white',
    // position: 'relative',
    // zIndex: -100000,
    ...mainFromThmeme,
  }

  const links = children.map(({ props }) => {
    return { text: props.title || props.id, link: `#${props.id}` }
  })

  return renderSchema({
    schema: {
      component: 'div',
      // css: LayoutCtx,
      css: {
        ...frame,
      },
      children: [
        {
          component: 'SEO',
          ...metadata,
          // currentLocale,
          // url: urlProp,
          // icon: iconProp,
          // lang: langPropDefined,
          // image: imageProp,
          // title: titleProp,
          // description: descriptionProp,
          // children,
        },
        {
          component: 'Intro',
          key: 'Intro',
          // css: IntroCtx,
          ...intro,
          css: {
            ...boxed,
          },
        },
        {
          component: 'Menu',
          key: 'Menu',
          links,
        },
        {
          component: 'main',
          key: 'main',
          // css: mainCtx,
          children,
          css: {
            ...boxed,
            ...main,
          },
        },
        {
          component: 'Contact',
          key: 'Contact',
          // css: ContactCtx,
          ...contact,
          contactInfos,
          css: {
            ...boxed,
          },
        },
      ],
    },
  })
}

export default Layout
