// import React from 'react'
// import { graphql } from 'gatsby'
import { renderSchema } from '../core/functions'

// import { Website } from `../../themes/${process.env.GATSBY_TOILE_THEME}/components`
// const { website } = require(`../../themes/${process.env.GATSBY_TOILE_THEME}/components`)
import * as toileThemes from '../../themes'
// import * as themeComponents from '../../temp/theme/components'

// import websiteData from '../../data/test'

const TemplateOnePage = ({
  pageContext: {
    data: { onePage, contactInfos, metadata, theme },
  },
}) => {
  const {
    contents: { intro, services, about, gallery, cta, contact } = {},
  } = onePage || { contents: {} }

  if (!intro && !services && !about && !gallery && !cta && !contact) {
    return renderSchema({ schema: { children: 'No content yet' } })
  }
  // const contactInfos = onePageData.website.contact
  // const theme = {
  //   // colors: onePageData.theme.colors || {},
  //   // palettes: onePageData.theme.palettes || [],
  //   // color: {},
  //   // palettes: [],
  // }

  const schema = {
    component: toileThemes[process.env.GATSBY_TOILE_THEME],
    theme,
    metadata,
    data: {
      intro,
      services,
      about,
      gallery,
      cta,
      contact,
      contactInfos,
    },
  }

  return renderSchema({ schema })
}

export default TemplateOnePage

// export default props => {
//   return import(`../../themes/${process.env.GATSBY_TOILE_THEME ||
//     'forty'}/components`)
//     .then(({ Website, ...rest }) => {
//       console.log({ Website, rest })
//       return Website
//     })
//     .then(websiteComponent => (
//       <TemplateOnePage {...{ websiteComponent, ...props }} />
//     ))
// }
