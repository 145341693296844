import { setSaturation, setLightness } from 'polished'
import {
  useContext,
  ThemeContext,
  RenderSchemaContext,
} from '~/src/core/contexts'

const Menu = ({ className, links }) => {
  const {
    units: { tem },
    scales: { h },
    cssHelpers: { setFlex, center },
    fluid,
    palette,
    defaults: {
      styles: { main: mainFromThmeme, boxed },
      paddings,
      margins,
    },
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  const menuItemSchema = ({ text, link }) => ({
    component: 'li',
    key: text,
    children: {
      component: 'Link',
      to: link,
      children: text,
      css: {
        display: 'block',
        // height: tem(h(0)),
        // lineHeight: tem(h(0)),
        ...paddings.navLinks(),
        ...margins.navLinks(),
        borderRadius: `.3em`,
        // ...fluid.column({
        //   properties: ['width'],
        //   maxCols: links.length,
        // }),
      },
    },
  })

  return renderSchema({
    schema: {
      component: 'nav',
      className,
      css: {
        ...mainFromThmeme,
        ...boxed,
        position: 'sticky',
        zIndex: 10,
        // top: `calc((${margins.navLinks().margin} * 2) + ${tem(h(0))})`,
        top: 0,
        ...palette(0, 'classic', ({ secondary }) => ({
          background: setLightness(0.9, setSaturation(0.2, secondary)),
        })).css.block,
      },
      children: {
        // component: 'List',
        tag: 'ul',
        // list,
        // components,
        css: {
          ...setFlex({ justify: 'center' }),
          ...center,
          // padding: `0 ${paddings.innerSection}`,
          'a:hover': {
            ...palette(0, 'funky').css.block,
          },
        },
        children: links.map(l => menuItemSchema(l)),
      },
    },
  })
}

export default Menu
