import {
  useContext,
  ThemeContext,
  RenderSchemaContext,
} from '~/src/core/contexts'

const Image = ({ title, text, image, length }) => {
  const {
    // units: { cell },
    // cssHelpers: { setFlex },
    fluid,
    // defaults: { paddings },
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  return renderSchema({
    schema: {
      component: 'BgImage',
      data: image,
      css: {
        // width: `20%`,
        // paddingBottom: `${length > 3 ? 100 / 3 : 100 / length}%`
        // height: 200,
        // width: '100%',
        // paddingBottom: '100%',
        // ...contexts.imageGallery, // TODO:
        ...fluid.column({
          properties: ['width', 'paddingBottom'],
          maxCols: 5,
        }),
      },
    },
  })
}

const Gallery = ({ title, text, list }) => {
  const {
    // units: { cell },
    cssHelpers: { setFlex },
    // fluid,
    defaults: { paddings, widths },
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  const components = Image

  return renderSchema({
    schema: {
      component: 'section',
      children: [
        {
          css: {
            ...paddings.section([1, 1, 0, 1]),
            // paddingLeft: paddings.section,
            // paddingRight: paddings.section,
          },
          children: [
            {
              component: 'Short',
              tag: 'h2',
              children: title,
              css: {
                ...widths.boxed(),
              },
            },
            {
              component: 'Long',
              children: text,
              css: {
                ...widths.boxed(),
                ...paddings.paragraph([0, 0, 1, 0]),
              },
            },
          ],
        },
        {
          component: 'List',
          list,
          components,
          css: {
            ...setFlex({}),
            ...widths.wide(),
          },
        },
      ],
    },
  })
}

export default Gallery
