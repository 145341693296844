import {
  useContext,
  ThemeContext,
  RenderSchemaContext,
} from '~/src/core/contexts'

const Layout = ({ contactInfos, children }) => {
  const {
    units: { cell },
    scales: { scale },
    cssHelpers: { center },
    fluid,
    defaults: {
      styles: { mainFrameWrapper, main: mainFromThmeme, boxed },
    },
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  return renderSchema({
    schema: {
      component: 'main',
      // css: mainCtx,
      children,
    },
  })
}

export default Layout
