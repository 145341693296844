import {
  useContext,
  ThemeContext,
  RenderSchemaContext,
} from '~/src/core/contexts'

const Button = ({ title, link: to }) => {
  const { renderSchema } = useContext(RenderSchemaContext)
  return renderSchema({
    schema: {
      component: 'li',
      children: [
        {
          component: 'Link',
          to,
          css: {
            display: 'block',
          },
          children: [
            {
              component: 'button',
              children: title,
            },
          ],
        },
      ],
    },
  })
}

const CTA = ({ title, text, buttons, ...props }) => {
  const {
    // units: { cell },
    cssHelpers: { center, setFlex },
    // fluid,
    defaults: { paddings, margins },
    palette,
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  return renderSchema({
    schema: {
      component: 'section',
      ...props,
      children: [
        {
          component: 'header',
          css: {
            ...paddings.section([0, 1, 1, 1]),
          },
          children: [
            {
              component: 'Short',
              tag: 'h2',
              children: title,
            },
            {
              component: 'Long',
              children: text,
            },
          ],
        },
        {
          component: 'List',
          tag: 'ul',
          list: buttons.list,
          components: [Button],
          css: {
            ...setFlex({}),
            ...paddings.section([0, 1]),
            button: {
              ...paddings.button(),
              borderRadius: `.3em`,
              ':hover': {
                ...palette(0, 'funky').css.hover,
              },
            },
          },
        },
      ],
    },
  })
}

export default CTA
