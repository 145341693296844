import {
  useContext,
  ThemeContext,
  RenderSchemaContext,
} from '~/src/core/contexts'

const Service = ({ title, text, image, length, index }) => {
  const {
    // units: { cell },
    cssHelpers: { setFlex },
    fluid,
    defaults: { colors, paddings },
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  return renderSchema({
    schema: {
      component: 'BgImage',
      key: `${title}${text}${image?.id}`,
      // tag: 'article',
      data: image,
      // fit: 'contain',
      overlay: index % 2 ? colors.cover : colors.altCover,
      transparentizeOverlay: true,
      css: {
        width: '100%',
        // paddingBottom: '100%',
        ...fluid.column({
          properties: ['width'], // paddingBottom here if we want to have squares
          maxCols: length,
        }),
      },
      children: {
        component: 'div',
        css: {
          // position: 'absolute', // with paddingBottom above if we want to have squares
          // top: 0,
          // right: 0,
          // bottom: 0,
          // left: 0,
          ...paddings.column(),
          ...setFlex({
            direction: 'column',
            justify: 'center',
            alignItems: 'flex-start',
            // alignContent: 'flex-start',
          }),
        },
        children: [
          {
            component: 'Short',
            tag: 'h3',
            children: title,
            css: {
              width: '100%',
            },
          },
          {
            component: 'Long',
            children: text,
          },
        ],
      },
    },
  })
}

const Services = ({ title, text, list }) => {
  const {
    cssHelpers: { setFlex },
    defaults: { paddings, widths },
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  const components = [Service]

  return renderSchema({
    schema: {
      component: 'section',
      children: [
        {
          css: {
            ...paddings.section([1, 1, 0, 1]),
            // paddingLeft: paddings.section,
            // paddingRight: paddings.section,
          },
          children: [
            {
              component: 'Short',
              tag: 'h2',
              text: title,
              css: {
                ...widths.boxed(),
              },
            },
            {
              component: 'Long',
              children: text,
              css: {
                ...paddings.paragraph([0, 0, 1, 0]),
                ...widths.boxed(),
              },
            },
          ],
        },
        {
          component: 'List',
          list,
          components,
          css: {
            ...setFlex({ align: 'stretch' }),
            ...widths.wide(),
          },
        },
      ],
    },
  })
}

export default Services
