import {
  useContext,
  ThemeContext,
  RenderSchemaContext,
} from '~/src/core/contexts'

const About = ({ title, text, image, ...props }) => {
  const {
    units: { cell },
    cssHelpers: { center, setFlex, setPosition },
    fluid,
    defaults: { paddings },
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  return renderSchema({
    schema: {
      component: 'section',
      ...props,
      css: {
        ...setFlex({ align: 'center' }),
      },
      children: [
        {
          component: 'div',
          css: {
            // width: col(3),
            // ...fluidLocks({
            //   properties: ['width'],
            //   min: [15, 20],
            //   max: [30, 40],
            //   // cols: [1, 2],
            //   minW: [15, 30],
            //   maxW: [30, 60],
            //   noMin: true,
            //   fullScaleWidth: `100%`,
            // }),
            ...fluid.column({
              properties: ['width'],
              maxCols: 3,
              takesCols: 2,
            }),
            ...paddings.column([0, 1]),
          },
          children: [
            {
              component: 'Short',
              tag: 'h2',
              children: title,
            },
            {
              component: 'Long',
              children: text,
            },
          ],
        },
        {
          component: 'div',
          css: {
            ...fluid.column({
              properties: ['width'],
              maxCols: 3,
            }),
            ...paddings.column(),
          },
          children: {
            component: 'Image',
            data: image,
            // size: cell(2),
            shape: 'circle',
            // fit: 'cover',
            // isFluid: true,
            // padding: paddings.column().padding,
            css: {
              border: `solid 1px`,
            },
          },
        },
      ],
    },
  })
}

export default About
