import {
  useContext,
  ThemeContext,
  RenderSchemaContext,
} from '~/src/core/contexts'

const Intro = ({ title, text, image, bgImage, className }) => {
  const {
    units: { cell },
    scales: { h },
    cssHelpers: { center },
    fluid,
    defaults: {
      styles: { mainFrameWrapper, main: mainFromThmeme, boxed },
      fontSizes,
      paddings,
    },
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  return renderSchema({
    schema: {
      tag: 'section',
      className,
      css: {
        ...center,
        // padding: `${h(0)}em`,
        ...paddings.hero([1, 1, 1, 1]),
      },
      children: [
        {
          component: 'Image',
          key: 'Image',
          data: image,
          // size: '4em',
          fit: 'contain',
          css: {
            height: cell(2),
            // fontSize: `${h2}em`,
          },
        },
        {
          component: 'Short',
          key: 'Short',
          tag: 'h1',
          text: title,
          css: {
            // ...contexts.inner,
            // borderBottom: 'solid 1px',
            // ...h1Size,
            // fontSize: `${h2}em`,
            // fontSize: u(h2 * 2),
            // fontSize: tem(h(1)),
          },
        },
        {
          component: 'Long',
          key: 'Long',
          children: text,
          // css: contexts.inner,
          css: {
            // ...pSize,
            // fontSize: `${h5}em`,
            // fontSize: fontSizes.subTitle,
          },
        },
      ],
    },
  })
}

export default Intro
