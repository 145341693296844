import deepmerge from 'deepmerge'

import {
  renderSchema,
  Components as CoreLibrary,
  // useContext,
  // ThemeContext,
  ThemeProvider,
  // RenderSchemaContext,
  RenderSchemaProvider,
} from '~/src/core'

import * as ThemeLibrary from '.'
import { themeDefaults as defaults } from '../themeDefaults'

const Website = ({ data, metadata, theme: userTheme }) => {
  const { intro, services, about, gallery, cta, contact, contactInfos } = data

  const overwriteIfNotEmpty = (destinationArray, sourceArray, options) =>
    sourceArray.length ? sourceArray : destinationArray
  const theme = deepmerge(
    {
      colors: { black: '#000', white: `#fff`, grey: `#888` },
      palettes: [
        {
          name: 'defaultBN',
          neutral: 'white',
          primary: 'black',
          secondary: 'grey',
        },
      ],

      fontFamily: 'Source Sans Pro,Helvetica,sans-serif',
      baseFontSize: 1,
      h1FontSize: 2.75,
      baseLineHeight: 1.2,
      // fontSizeScale = 1.225,
      fullWidth: 60, // this is in em
      // fullScaleWidth = 0.8,
    },
    userTheme,
    {
      arrayMerge: overwriteIfNotEmpty,
    },
  )

  const schema = {
    component: RenderSchemaProvider,
    library: { ...CoreLibrary, ...ThemeLibrary },
    // createElement, // TODO: optional
    children: {
      component: ThemeProvider,
      theme,
      defaults,
      children: [
        {
          component: CoreLibrary.CssReset,
          children: [
            {
              component: ThemeLibrary.OnePage,
              metadata,
              intro,
              services,
              about,
              gallery,
              cta,
              contact,
              contactInfos,
            },
          ],
        },
      ],
    },
  }

  return renderSchema({
    schema,
  })
}

export default Website
