// import { IoIosArrowRoundForward } from 'react-icons/io'

import {
  useContext,
  ThemeContext,
  RenderSchemaContext,
} from '~/src/core/contexts'

const Button = ({ title, link: to }) => {
  const {
    // units: { cell },
    // cssHelpers: { setFlex },
    // fluid,
    defaults: { paddings, margins, styles },
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  return renderSchema({
    schema: {
      component: 'Link',
      tag: 'li',
      to,
      children: [
        {
          component: 'button',
          css: {
            ...paddings.button(),
            ...margins.button([0, 1, 1, 0]),
            ...styles.button,
          },
          children: [
            {
              component: 'span',
              children: title,
            },
            // {
            //   component: IoIosArrowRoundForward,
            //   css: {
            //     ...styles.buttonIcon,
            //   },
            // },
          ],
        },
      ],
    },
  })
}
// const Button = {
//   componentFromObject: ({ title, link: to }) => ({
//     component: 'li',
//     children: [
//       {
//         component: 'Link',
//         to,
//         children: title,
//       },
//     ],
//   }),
// }

const CTA = ({ title, text, buttons }) => {
  const {
    // units: { cell },
    cssHelpers: { setFlex },
    // fluid,
    defaults: { paddings, widths },
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  return renderSchema({
    schema: {
      component: 'section',
      css: {
        ...paddings.section(),
      },
      children: [
        {
          component: 'Short',
          tag: 'h2',
          children: title,
          css: {
            ...widths.boxed(),
          },
        },
        {
          component: 'Long',
          children: text,
          css: {
            ...paddings.paragraph([0, 0, 1, 0]),
            ...widths.boxed(),
          },
        },
        {
          component: 'List',
          tag: 'ul',
          list: buttons?.list,
          components: [Button],
          css: {
            ...setFlex({}),
            ...widths.boxed(),
          },
        },
      ],
    },
  })
}

export default CTA
