const Intro = ({ renderSchema, theming, title, text, image, bgImage }) => {
  const {
    units: { tem, cell },
    // scales: { scale, h },
    cssHelpers: { setAlign, setFlex },
    // fluid,
    palette,
    defaults: { paddings, widths, colors, fontSizes, styles },
  } = theming

  return renderSchema({
    schema: {
      component: 'BgImage',
      tag: 'section',
      data: bgImage,
      overlay: colors.cover,
      css: [
        {
          ...paddings.hero(),
          // position: 'relative',
          ...palette(0).css.base,
          // ...setFlex({ flow: 'column', alignItems: 'flex-start' }),
        },
      ],
      children: [
        {
          component: 'div',
          css: {
            ...widths.boxed(),
          },
          children: {
            component: 'Image',
            data: image,
            fit: 'contain',
            size: cell(2),
            css: {},
          },
        },
        {
          component: 'Short',
          tag: 'h1',
          children: title,
          css: {
            ...widths.boxed(),
          },
        },
        {
          component: 'Long',
          children: text,
          css: {
            ...widths.boxed(),
            ...styles.subTitle,
          },
        },
      ],
    },
  })
}

export default Intro
