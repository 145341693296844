import { useTheming, useRenderSchema } from '~/src/core'

const OnePage = ({
  metadata,
  intro,
  services,
  about,
  gallery,
  cta,
  contact,
  contactInfos,
}) => {
  const renderSchema = useRenderSchema()
  const theming = useTheming()
  const {
    defaults: {
      styles: { globals: globalStyles },
    },
  } = theming

  const doesExist = section => {
    return Boolean(
      section.title ||
        (section.text && section.text !== '<p><br></p>') ||
        section.image ||
        section.bgImage ||
        (section.list && section.list[0]) ||
        (section.buttons && section.buttons[0]),
    )
  }

  const mainSchema = [
    doesExist(services) && {
      component: 'Services',
      // css: ServicesCtx,
      ...services,
      id: `services`,
      key: `services`,
    },
    doesExist(about) && {
      component: 'About',
      // css: AboutCtx,
      ...about,
      id: `about`,
      key: `about`,
    },
    doesExist(gallery) && {
      component: 'Gallery',
      // css: GalleryCtx,
      ...gallery,
      id: `gallery`,
      key: `gallery`,
    },
    doesExist(cta) && {
      component: 'CTA',
      // css: CTACtx,
      ...cta,
      id: `cta`,
      key: `cta`,
    },
  ].filter(elem => elem)

  const layoutsSchema = {
    component: 'GlobalStyles',
    key: 'GlobalStyles',
    styles: globalStyles,
    children: {
      component: 'Layout',
      key: 'Layout',
      metadata,
      intro,
      contact,
      contactInfos,
      children: [...mainSchema],
    },
  }

  return renderSchema({
    schema: layoutsSchema,
  })
}

export default OnePage
