import { setLightness, transparentize, mix } from 'polished'
import {
  // setAlign,
  setMargins,
  setPaddings,
  // setFlex,
  // setPosition,
} from '~/src/core/theming/utils/cssHelpers'

export const themeDefaults = ({
  // colors,
  // color,
  // palettes,
  palette,
  units: { tem, u, col, cell, prct },
  scales: { scale, h, reducedScale },
  fluid,
  typography,
}) => {
  const colors = {
    cover: transparentize(0.2, palette().background),
    altCover: transparentize(
      0.2,
      mix(0.3, palette().secondary, palette().background),
    ),
  }
  const contrastPalette = palette({
    neutral: palette(0).primary,
    primary: palette(0).neutral,
    secondary: palette(0).secondary,
  })
  const funkyPalette = palette({
    neutral: palette(0).neutral,
    primary: palette(0).secondary,
    secondary: palette(0).primary,
  })
  const fontSizes = {
    h1: tem(h(1)),
    h2: tem(h(3)),
    h3: tem(h(5)),
    h4: tem(scale(0)),
    subTitle: tem(scale(-1)),
    p: tem(scale(0)),
    button: tem(scale(-2)),
  }
  const paddings = {
    // section: tem(scale(0)),
    // column: tem(scale(0)),
    // innerSection: tem(h(-1), 'rem'),
    // sectionTitleBottom: tem(h(3), 'rem'),
    // sectionDescriptionBottom: tem(scale(0), 'rem'),
    // button: tem(scale(0)),
    // paragraph: tem(scale(0)),

    mainX: tem(scale(5)),
    mainY: tem(scale(3)),
  }
  paddings.hero = a => setPaddings({ x: paddings.mainX, y: tem(scale(8)) }, a)
  paddings.section = a =>
    setPaddings({ x: paddings.mainX, y: paddings.mainY }, a)
  paddings.column = a => setPaddings({ p: paddings.mainX }, a)
  paddings.paragraph = a => setPaddings({ p: tem(scale(0)) }, a)
  paddings.button = a => setPaddings({ p: tem(scale(0)) }, a)

  const margins = {}
  margins.column = a => setMargins({ m: paddings.mainX }, a)
  margins.picto = a => setMargins({ m: tem(scale(-1)) }, a)
  margins.button = a => setMargins({ m: tem(scale(0)) }, a)

  // Widths are containers widths while ...
  const widths = {
    boxed: (s = 1) => ({
      maxWidth: prct(s * 100, 'rem'),
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    }),
    wide: (s = 1) => ({
      maxWidth: prct(s * 150, 'rem'),
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    }),
    // unit: u(1), // 0.5em canonical
    // cell: col(1 / 3), // === u(10)
    // column: col(1), // 15em canonical = 240px
    // paragraph: col(2),
  }
  // Spaces are paddings
  // const spaces = {}

  const styles = {
    boxed: {
      maxWidth: prct(100, 'rem'),
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    box: {
      //   ...fluid.locks(
      //     {
      //       properties: ['padding'],
      //       min: ,
      //       max: ,
      //     },
      // ),
      // padding: paddings.section,
      ' > *': {
        maxWidth: prct(100, 'rem'),
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    subTitle: {
      textTransform: 'uppercase',
      fontSize: fontSizes.subTitle,
      letterSpacing: '.25em',
    },
    button: {
      textTransform: 'uppercase',
      fontSize: fontSizes.button,
      letterSpacing: '.25em',
      // padding: paddings.button,
      // marginRight: paddings.button,
      borderWidth: '2px',
      ':hover': {
        ...funkyPalette.css.base,
        ...funkyPalette.css.background,
      },
      // ':after': {
      //   backgroundImage: `url(data:image/svg+xml;charset=utf8,svg xmlns='http://www.w3.org/2000/svg' x…34' y2='12.5' / line x1='25' y1='20' x2='34' y2='11.5' / /svg)`,
      //   opacity: 0,
      //   zIndex: 1,
      //   backgroundPosition: '100%',
      //   backgroundRepeat: 'no-repeat',
      //   backgroundSize: '36px 24px',
      //   content: "''",
      //   display: 'block',
      //   height: '100%',
      //   position: 'absolute',
      //   right: '1.5em',
      //   top: 0,
      //   verticalAlign: 'middle',
      //   width: '36px',
      // },
    },
    // buttonIcon: {
    //   width: tem(scale(4)),
    //   height: tem(scale(4)),
    // },
    contactIconWrapper: {
      width: tem(scale(-1) * 2),
      height: tem(scale(-1) * 2),
      padding: `${tem(scale(-2) / 2)} ${tem(scale(-1) / 2)}`,
      // ...contrastPalette.css.base,
      // ...contrastPalette.css.background,
      borderRadius: '50%',
      // border: 'solid 1px',
      // marginRight: tem(scale(-1)),
      ...margins.picto([0, 1, 1, 0]),

      svg: {
        width: tem(scale(-1)),
        height: tem(scale(-1)),
      },
    },

    // ------------------------------------------------------------------------
    // GLOBALS
    // ------------------------------------------------------------------------
    globals: {
      html: {
        fontFamily: typography.fontFamily,
        scrollBehavior: 'smooth',
        ...fluid.locks([
          {
            properties: ['fontSize'],
            min: typography.baseFontSize,
            max: typography.fontSizeScale * typography.baseFontSize,
            minW: 30,
            maxW: 60,
            unit: 'rem',
            fullScaleWidth: '100vw',
          },
          // {
          //   properties: ['lineHeight'],
          //   min: typography.baseLineHeight,
          //   max: typography.fontSizeScale * typography.baseLineHeight,
          // },
        ]),
        lineHeight: 1.2,
        ...palette(0).css.base,
      },
      h1: {
        fontSize: fontSizes.h1,
        borderBottom: 'solid 2px',
        fontWeight: '600',
        // lineHeight: 2,
        paddingBottom: tem(reducedScale(5) / 2, 'rem'),
        marginBottom: tem(scale(5) / 2, 'rem'),
        marginTop: tem(scale(5) / 2, 'rem'),
      },
      h2: {
        fontSize: fontSizes.h2,
        borderBottom: 'solid 2px',
        fontWeight: '600',
        // lineHeight: 2,
        paddingBottom: tem(reducedScale(3) / 2, 'rem'),
        marginBottom: tem(scale(3) / 2, 'rem'),
        marginTop: tem(scale(3) / 2, 'rem'),
      },
      h3: {
        fontSize: fontSizes.h3,
        borderBottom: 'solid 2px',
        fontWeight: '600',
        // lineHeight: 2,
        paddingBottom: tem(reducedScale(1) / 2, 'rem'),
        marginBottom: tem(scale(1) / 2, 'rem'),
        marginTop: tem(scale(1) / 2, 'rem'),
      },
      h4: {
        fontSize: fontSizes.h4,
        fontWeight: '600',
      },
      'a, button, .button': {
        transition: 'color .1s, background-color .3s, border-color .1s',
      },
      // main: {
      //   section: {
      //     padding: `${paddings.section} 0`,
      //     borderBottom: 'solid 1px lightgreen',
      //     ':last-child': {
      //       borderBottom: 'none',
      //     },
      //     ' > *': {
      //       paddingLeft: paddings.innerSection,
      //       paddingRight: paddings.innerSection,
      //     },
      //     ' h2:after': {
      //       ...afterSectionTitle,
      //     },
      //   },
      // },
      // 'button, .button': {
      //   padding: paddings.button,
      // },
    },
  }

  return {
    colors,
    fontSizes,
    paddings,
    margins,
    widths,
    styles,
  }
}
