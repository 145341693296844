import {
  useContext,
  ThemeContext,
  RenderSchemaContext,
} from '~/src/core/contexts'

const About = ({ title, text, image }) => {
  const {
    // units: { cell },
    cssHelpers: { setFlex, setAlign },
    fluid,
    defaults: { paddings, margins, styles, widths },
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  return renderSchema({
    schema: {
      component: 'section',
      css: {
        ...paddings.section([1, 0, 1, 0]),
        ...widths.boxed(),
      },
      children: {
        component: 'div',
        css: {
          // ...contexts.inner, // TODO:
          // display: 'flex',
          // alignItems: 'center',
          ...setFlex({ justify: 'center', align: 'center' }),
          // ...styles.box,
        },
        children: [
          image && {
            component: 'Image',
            data: image,
            // size: 200,
            // shape: 'square',
            css: {
              ...fluid.column({
                properties: ['width', 'height'],
                maxCols: 3,
              }),
            },
          },
          {
            component: 'div',
            css: {
              ...paddings.column(),
              // TODO: add media query to avoid padding right when screen is larger than full scale
              ...fluid.column({
                properties: ['width', 'height'],
                maxCols: 3,
                takesCols: image ? 2 : 3,
              }),
            },
            children: [
              {
                component: 'Short',
                tag: 'h2',
                children: title,
              },
              {
                component: 'Long',
                children: text,
              },
            ],
          },
        ],
      },
    },
  })
}

export default About
