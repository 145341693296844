import React from 'react'
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa'

import {
  useContext,
  ThemeContext,
  RenderSchemaContext,
} from '~/src/core/contexts'

const dico = {
  facebook: {
    fr: 'Facebook',
    icon: FaFacebookF,
    comp: link => ({
      component: 'Link',
      tag: 'span',
      to: link,
    }),
  },
  instagram: {
    fr: 'Instagram',
    icon: FaInstagram,
    comp: link => ({
      component: 'Link',
      tag: 'span',
      to: link,
    }),
  },
  linkedin: {
    fr: 'LinkedIn',
    icon: FaLinkedinIn,
    comp: link => ({
      component: 'Link',
      tag: 'span',
      to: link,
    }),
  },
}

const ContactElem = ({ length, type, value }) => {
  const {
    // units: { tem },
    // scales: { h },
    // cssHelpers: { setFlex },
    // fluid,
    defaults: { styles },
    // palette,
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  // const { comp } = dico[type]

  return value
    ? renderSchema({
        schema: {
          component: 'Link',
          to: value,
          // className: 'button',
          css: {
            display: 'block',
            ...styles.contactIconWrapper,
            // ...palette(0, 'disco').css.button,
          },
          children: {
            component: dico[type].icon,
            css: {
              ...styles.contactIcon,
            },
          },
        },
      })
    : null
}

const Footer = ({ title, text, image, contactInfos }) => {
  const {
    units: { tem },
    scales: { h, scale },
    cssHelpers: { setFlex },
    // fluid,
    defaults: { paddings, colors, widths },
    palette,
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  const { facebook, instagram, linkedin } = contactInfos || {}

  const components = [ContactElem]

  return renderSchema({
    schema: {
      component: 'section',
      css: {
        ...paddings.section(),
        borderTop: `solid 1px`,
        ...palette(0, 'funky').css.base,
        ...widths.wide(),
      },
      children: [
        {
          component: 'List',
          list: Object.entries({ facebook, instagram, linkedin }).map(
            ([type, value]) => ({ type, value }),
          ),
          css: {
            ...setFlex({}),
            ...widths.boxed(),
            a: {
              ...palette(0, 'funkyContrast').css.button,
            },
          },
          components,
        },
        {
          component: () => (
            <ul
              css={{
                ...setFlex({}),
                fontSize: tem(scale(-1)),
                ...widths.boxed(),
              }}
            >
              <li>
                Powered by{' '}
                <a
                  href="https://www.toile.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  toile.io
                </a>
              </li>
              <span css={{ padding: `0 1em` }}>|</span>
              <li>
                Design inspired by{' '}
                <a
                  href="https://html5up.net/?ref=toile.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HTML5 UP
                </a>
              </li>
            </ul>
          ),
        },
      ],
    },
  })
}

export default Footer
