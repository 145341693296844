import {
  FaEnvelope,
  FaPhone,
  FaHome,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
} from 'react-icons/fa'

import {
  useContext,
  ThemeContext,
  RenderSchemaContext,
} from '~/src/core/contexts'

const dico = {
  email: {
    fr: 'Email',
    icon: FaEnvelope,
    comp: email => ({
      component: 'Link',
      to: `mailto:${email}`,
      children: email,
    }),
  },
  phone: {
    fr: 'Téléphone',
    icon: FaPhone,
    comp: phone => ({
      component: 'span',
      children: phone,
    }),
  },
  address: {
    fr: 'Adresse',
    icon: FaHome,
    comp: address => ({
      component: 'span',
      dangerouslySetInnerHTML: {
        __html: address,
      },
    }),
  },
  facebook: {
    fr: 'Facebook',
    icon: FaFacebookF,
    comp: link => ({
      component: 'Link',
      tag: 'span',
      to: link,
    }),
  },
  instagram: {
    fr: 'Instagram',
    icon: FaInstagram,
    comp: link => ({
      component: 'Link',
      tag: 'span',
      to: link,
    }),
  },
  linkedin: {
    fr: 'LinkedIn',
    icon: FaLinkedinIn,
    comp: link => ({
      component: 'Link',
      tag: 'span',
      to: link,
    }),
  },
}

const ContactElem = ({ length, type, value }) => {
  const {
    // units: { tem },
    // scales: { h },
    cssHelpers: { setFlex },
    fluid,
    defaults: { styles, paddings },
    palette,
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  const { comp } = dico[type]

  return value
    ? renderSchema({
        schema: {
          component: 'div',
          key: type,
          css: {
            ...fluid.column({ properties: ['width'], maxCols: 3 }),
            ...setFlex({ flow: 'row' }),
            // paddingRight: paddings.column,
            // paddingLeft: paddings.column,
            ...paddings.column(),
          },
          children: [
            {
              css: {
                ...styles.contactIconWrapper,
                ...palette(0, 'contrast').css.base,
                ...palette(0, 'contrast').css.background,
              },
              key: 'icon',
              children: {
                component: dico[type].icon,
                css: {
                  ...styles.contactIcon,
                },
              },
            },
            {
              key: 'texts',
              children: [
                {
                  component: 'Short',
                  tag: 'h4',
                  children: dico[type].fr,
                },
                comp && comp(value),
              ],
            },
          ],
        },
      })
    : null
}

const Contact = ({ title, text, image, contactInfos }) => {
  const {
    // units: { tem },
    // scales: { h },
    cssHelpers: { setFlex },
    // fluid,
    defaults: { paddings, widths },
    // palette,
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  const { email, phone, address } = contactInfos || {}

  const components = [ContactElem]

  return renderSchema({
    schema: {
      component: 'section',
      css: {
        // ...styles.box,
      },
      children: [
        {
          component: 'div',
          css: {
            ...paddings.section([1, 1, 0, 1]),
            // paddingLeft: paddings.section,
            // paddingRight: paddings.section,
          },
          children: [
            {
              component: 'Short',
              tag: 'h2',
              text: title,
              css: {
                ...widths.boxed(),
              },
            },
            {
              component: 'Long',
              children: text,
              css: {
                ...paddings.paragraph([0, 0, 1, 0]),
                ...widths.boxed(),
              },
            },
          ],
        },
        {
          component: 'List',
          list: Object.entries({ email, phone, address }).map(
            ([type, value]) => ({ type, value }),
          ),
          css: {
            ...setFlex({}),
            ...widths.boxed(),
          },
          components,
        },
      ],
    },
  })
}

export default Contact
