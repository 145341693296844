import { setLightness, getLuminance } from 'polished'
import {
  // setAlign,
  setMargins,
  setPaddings,
  setBorders,
  setBorderRadii,
  // setFlex,
  // setPosition,
} from '~/src/core/theming/utils/cssHelpers'

export const themeDefaults = ({
  colors,
  color,
  palettes,
  palette,
  units: { tem, u, col, cell, prct },
  scales: { scale, h },
  fluid,
  typography,
}) => {
  const frameWrapperColors = {
    neutral: palettes[0]?.primary,
    primary: palettes[0]?.neutral,
    secondary: palettes[0]?.secondary,
    gradient: {
      start: setLightness(0.5, palettes[0].secondary),
      end: setLightness(0.3, palettes[0].secondary),
    },
  }

  const fontSizes = {
    h1: tem(h(1)),
    h2: tem(h(3)),
    h3: tem(h(5)),
    h4: tem(scale(0)),
    subTitle: tem(h(5)),
    p: tem(scale(0)),
  }
  const paddings = {
    column: tem(scale(0)),
    section: tem(h(1)),
    innerSection: tem(h(-1), 'rem'),
    sectionTitleBottom: tem(h(3), 'rem'),
    sectionDescriptionBottom: tem(scale(0), 'rem'),
    button: tem(scale(0)),

    mainX: tem(scale(5)),
    mainY: tem(scale(3)),
  }
  paddings.hero = a => setPaddings({ x: paddings.mainX, y: tem(scale(8)) }, a)
  paddings.section = a =>
    setPaddings({ x: paddings.mainX, y: paddings.mainY }, a)
  paddings.column = a => setPaddings({ p: paddings.mainX }, a)
  paddings.paragraph = a => setPaddings({ p: tem(scale(0)) }, a)
  paddings.h3 = a => setPaddings({ p: tem(h(9)) }, a)
  paddings.button = a => setPaddings({ x: tem(scale(0)), y: tem(scale(-5)) }, a)
  paddings.navLinks = a =>
    setPaddings({ x: tem(scale(0)), y: tem(scale(-5)) }, a)

  const margins = {
    mainX: tem(scale(5)),
    mainY: tem(scale(3)),
  }
  margins.h3 = a => setMargins({ p: tem(h(9)) }, a)
  margins.section = a => setMargins({ x: margins.mainX, y: margins.mainY }, a)
  margins.column = a => setMargins({ m: paddings.mainX }, a)
  margins.picto = a => setMargins({ m: tem(scale(-1)) }, a)
  margins.button = a => setMargins({ m: tem(scale(0)) }, a)
  margins.navLinks = a => setMargins({ m: tem(scale(-3)) }, a)

  const borders = {}
  borders.section = a => setBorders({ all: 'solid 1px' }, a)

  // const borderRadii = {}
  // borderRadii.main = a => setBorderRadii({ all: '1em' }, a)

  // Widths are containers widths while ...
  const widths = {
    unit: u(1), // 0.5em canonical
    cell: col(1 / 3), // === u(10)
    column: col(1), // 15em canonical = 240px
    paragraph: col(2),
    boxed: (s = 1) => ({
      maxWidth: prct(s * 100, 'rem'),
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    }),
    wide: (s = 1) => ({
      maxWidth: prct(s * 150, 'rem'),
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    }),
  }
  // Spaces are paddings
  // const spaces = {}

  const styles = {
    boxed: {
      ...widths.boxed(),
      // maxWidth: '60em',
      // margin: 'auto',
    },
    mainFrameWrapper: {
      color: frameWrapperColors.primary,
      backgroundColor: frameWrapperColors.gradient.start,
      backgroundImage: `linear-gradient(45deg,${
        frameWrapperColors.gradient.start
      } 15%,${frameWrapperColors.gradient.end} 85%)`,
    },
    main: {
      ...palette().css.block,
      // ...borderRadii.main(),
    },
    afterSectionTitle: {
      display: 'block',
      content: "''",
      // width: '3.25em',
      width: cell(1, 'rem'),
      height: '2px',
      margin: `${tem(scale(0), 'rem')} 0 ${tem(h(2), 'rem')}`,
      borderRadius: '2px',
      backgroundColor: '#ddd',
      backgroundImage: `linear-gradient(90deg,${
        frameWrapperColors.gradient.start
      },${frameWrapperColors.gradient.end})`,
    },
  }
  // ------------------------------------------------------------------------
  // GLOBALS
  // ------------------------------------------------------------------------
  styles.globals = {
    html: {
      fontFamily: typography.fontFamily,
      lineHeight: typography.baseLineHeight,
      fontWeight: 300,
      scrollBehavior: 'smooth',
      ...fluid.locks([
        {
          properties: ['fontSize'],
          min: typography.baseFontSize,
          max: typography.fontSizeScale * typography.baseFontSize,
          minW: 30,
          maxW: 60,
          unit: 'rem',
          fullScaleWidth: '100vw',
        },
        // {
        //   properties: ['lineHeight'],
        //   min: typography.baseLineHeight,
        //   max: typography.fontSizeScale * typography.baseLineHeight,
        // },
      ]),
      ...palette(0, 'contrast').css.base,
    },
    h1: {
      fontSize: fontSizes.h1,
      // borderBottom: 'solid 2px',
      // fontWeight: '600',
      // // lineHeight: 2,
      // paddingBottom: tem(reducedScale(5) / 2, 'rem'),
      // marginBottom: tem(scale(5) / 2, 'rem'),
      // marginTop: tem(scale(5) / 2, 'rem'),
    },
    h2: {
      fontSize: fontSizes.h2,
      // borderBottom: 'solid 2px',
      // fontWeight: '600',
      // // lineHeight: 2,
      // paddingBottom: tem(reducedScale(3) / 2, 'rem'),
      // marginBottom: tem(scale(3) / 2, 'rem'),
      // marginTop: tem(scale(3) / 2, 'rem'),
    },
    h3: {
      fontSize: fontSizes.h3,
      // borderBottom: 'solid 2px',
      // fontWeight: '600',
      // // lineHeight: 2,
      // paddingBottom: tem(reducedScale(1) / 2, 'rem'),
      // marginBottom: tem(scale(1) / 2, 'rem'),
      // marginTop: tem(scale(1) / 2, 'rem'),
    },
    // h4: {
    //   fontSize: fontSizes.h4,
    //   fontWeight: '600',
    // },
    // 'a, button, .button': {
    //   transition: 'color .1s, background-color .3s, border-color .1s',
    // },

    nav: {
      borderRadius: `.3em .3em 0 0`,
    },
    main: {
      ...palette(0).css.base,
      borderRadius: `0 0 .3em .3em`,
      section: {
        ...paddings.section([1, 0]),
        // padding: `${paddings.section} 0`,
        borderBottom: 'solid 1px',
        ...palette(0, 'funky').css.border,
        ':last-child': {
          borderBottom: 'none',
        },
        // ' > *': {
        //   paddingLeft: paddings.innerSection,
        //   paddingRight: paddings.innerSection,
        // },
        ' h2:after': {
          ...styles.afterSectionTitle,
        },
      },
    },
    'button, .button': {
      fontFamily: typography.fontFamily,
      fontWeight: 300,
      ...paddings.button(),
      ...margins.button([0, 1, 0, 0]),
    },
    '.Long': {
      // 'ul, ol': {
      //   paddingLeft: '1em',
      // },
      // ul: {
      //   listStyle: `disc outside none`,
      // },
    },
  }

  return {
    // colors,
    fontSizes,
    paddings,
    margins,
    widths,
    styles,
  }
}
