import React from 'react'
import {
  FaEnvelope,
  FaPhone,
  FaHome,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
} from 'react-icons/fa'

import {
  useContext,
  ThemeContext,
  RenderSchemaContext,
} from '~/src/core/contexts'

const dico = {
  email: {
    fr: 'Email',
    icon: FaEnvelope,
    comp: email => ({
      component: 'Link',
      tag: 'span',
      to: `mailto:${email}`,
      children: email,
    }),
  },
  phone: {
    fr: 'Téléphone',
    icon: FaPhone,
    comp: phone => ({
      component: 'span',
      children: phone,
    }),
  },
  address: {
    fr: 'Adresse',
    icon: FaHome,
    comp: address => ({
      component: 'span',
      key: 'address',
      dangerouslySetInnerHTML: { __html: address },
    }),
  },
  facebook: {
    fr: 'Facebook',
    icon: FaFacebookF,
    comp: link => ({
      component: 'Link',
      tag: 'span',
      to: link,
    }),
  },
  instagram: {
    fr: 'Instagram',
    icon: FaInstagram,
    comp: link => ({
      component: 'Link',
      tag: 'span',
      to: link,
    }),
  },
  linkedin: {
    fr: 'LinkedIn',
    icon: FaLinkedinIn,
    comp: link => ({
      component: 'Link',
      tag: 'span',
      to: link,
    }),
  },
}

const ContactLine = ({ length, type, value }) => {
  const {
    // units: { cell },
    // cssHelpers: { center, setFlex },
    // fluid,
    defaults: { paddings },
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  const { comp, icon, fr } = dico[type]

  return value
    ? renderSchema({
        schema: {
          component: 'div',
          tag: 'p',
          key: type,
          css: {
            ...paddings.paragraph([0, 1, 1, 1]),
          },
          children: [
            {
              component: 'Short',
              key: 'fr',
              text: `${fr}: `,
            },
            {
              component: 'span',
              key: 'val',
              children: comp(value),
            },
          ],
        },
      })
    : null
}

const ContactElem = ({ length, type, value }) => {
  const {
    units: { tem },
    scales: { h },
    // cssHelpers: { center, setFlex },
    fluid,
    // defaults: { paddings },
    palette,
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  const { comp, fr, icon } = dico[type]

  return value
    ? renderSchema({
        schema: {
          component: 'div',
          key: type,
          css: {
            // ...fluid.column({
            //   properties: ['width', 'height'],
            //   maxCols: 12,
            // }),
          },
          children: [
            {
              component: 'Link',
              to: value,
              css: {
                display: 'block',
                width: tem(h(0)),
                height: tem(h(0)),
                border: 'solid 1px',
                borderRadius: '50%',
                margin: tem((h(0) - h(5)) / 2),
                padding: tem((h(0) - h(5)) / 2),
                ':hover': {
                  ...palette(0, 'funkyContrast').css.hover,
                },
              },
              children: {
                component: icon,
                css: {
                  width: tem(h(5)),
                  height: tem(h(5)),
                },
              },
            },
          ],
        },
      })
    : null
}

const Contact = ({ title, text, image, contactInfos, ...props }) => {
  const {
    units: { tem },
    scales: { scale },
    cssHelpers: { center, setFlex },
    // fluid,
    defaults: { paddings },
    palette,
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  const { email, phone, address, facebook, instagram, linkedin } =
    contactInfos || {}

  return renderSchema({
    schema: {
      component: 'section',
      ...props,
      css: {
        ...center,
        ...paddings.hero([1, 0]),
        ...palette(0, 'funkyContrast').css.base,
      },
      children: [
        {
          component: 'header',
          key: 'header',
          css: {
            ...paddings.section([0, 1, 1, 1]),
          },
          children: [
            {
              component: 'Short',
              children: title,
            },
            {
              component: 'Long',
              children: text,
            },
          ],
        },
        {
          component: 'List',
          key: 'lines',
          list: Object.entries({ email, phone, address })
            .filter(([key, val]) => val)
            .map(([type, value]) => ({ type, value })),
          components: [ContactLine],
        },
        {
          component: 'List',
          key: 'icons',
          css: {
            ...setFlex({ justify: 'center' }),
          },
          list: Object.entries({ facebook, instagram, linkedin })
            .filter(([key, val]) => val)
            .map(([type, value]) => ({ type, value })),
          components: [ContactElem],
        },
        {
          component: () => (
            <ul
              key="footer toile"
              css={{
                ...setFlex({ justify: 'center' }),
                fontSize: tem(scale(-2)),
                // ...widths.boxed(),
                textAlign: 'center',
                ...paddings.hero([1, 0, 0, 0]),
              }}
            >
              <li>
                Powered by{' '}
                <a
                  href="https://www.toile.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  toile.io
                </a>
              </li>
              <span css={{ padding: `0 1em` }}>|</span>
              <li>
                Design inspired by{' '}
                <a
                  href="https://html5up.net/?ref=toile.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HTML5 UP
                </a>
              </li>
            </ul>
          ),
        },
      ],
    },
  })
}

export default Contact
