import { useTheming, useRenderSchema } from '~/src/core'

const OnePage = ({
  metadata,
  intro,
  services,
  about,
  gallery,
  cta,
  contact,
  contactInfos,
}) => {
  const renderSchema = useRenderSchema()
  const theming = useTheming()
  const {
    defaults: {
      styles: { globals: globalStyles },
    },
  } = theming

  const doesExist = section => {
    return Boolean(
      section.title ||
        (section.text && section.text !== '<p><br></p>') ||
        section.image ||
        section.bgImage ||
        (section.list && section.list[0]) ||
        (section.buttons && section.buttons[0]),
    )
  }

  const mainSchema = [
    {
      component: 'SEO',
      ...metadata,
    },
    doesExist(intro) && {
      component: 'Intro',
      ...intro,
      renderSchema,
      theming,
      id: `intro`,
    },
    doesExist(services) && {
      component: 'Services',
      ...services,
      id: `services`,
    },
    doesExist(about) && {
      component: 'About',
      ...about,
      id: `about`,
    },
    doesExist(gallery) && {
      component: 'Gallery',
      ...gallery,
      id: `gallery`,
    },
    doesExist(cta) && {
      component: 'CTA',
      ...cta,
      id: `cta`,
    },
    doesExist(contact) && {
      component: 'Contact',
      ...contact,
      contactInfos,
      id: `contact`,
    },
    {
      component: 'Footer',
      contactInfos,
      id: `footer`,
    },
  ]

  const layoutsSchema = {
    component: 'GlobalStyles',
    styles: globalStyles,
    // children: {
    //   component: 'Layout',
    //   contactInfos,
    children: [...mainSchema],
    // },
  }

  return renderSchema({
    schema: layoutsSchema,
  })
}

export default OnePage
