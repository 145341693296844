import {
  useContext,
  ThemeContext,
  RenderSchemaContext,
} from '~/src/core/contexts'

const Image = ({ title, text, image, length }) => {
  const {
    // units: { cell },
    // cssHelpers: { center, setFlex },
    fluid,
    defaults: { paddings },
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  return renderSchema({
    schema: {
      component: 'BgImage',
      data: image,
      css: {
        width: '100%',
        paddingBottom: '100%',
        // ...contexts.imageGallery,
        ...fluid.column({
          properties: ['width', 'paddingBottom'],
          maxCols: 6,
        }),
        flexGrow: 1,
      },
    },
  })
}

const Gallery = ({ title, text, list, ...props }) => {
  const {
    //   units: { cell },
    cssHelpers: { setFlex },
    //   fluid,
    defaults: { paddings },
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  const components = Image

  return renderSchema({
    schema: {
      component: 'section',
      ...props,
      children: [
        {
          component: 'header',
          css: {
            ...paddings.section([0, 1, 1, 1]),
          },
          children: [
            {
              component: 'Short',
              tag: 'h2',
              children: title,
            },
            {
              component: 'Long',
              children: text,
            },
          ],
        },
        {
          component: 'List',
          list,
          components,
          css: {
            // display: 'flex',
            // flexFlow: 'row wrap',
            ...setFlex({}),
            ...paddings.section([0, 1]),
          },
        },
      ],
    },
  })
}

export default Gallery
