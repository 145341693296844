import {
  useContext,
  ThemeContext,
  RenderSchemaContext,
} from '~/src/core/contexts'

const Service = ({ title, text, image, length }) => {
  const {
    units: { cell },
    cssHelpers: { center },
    fluid,
    defaults: { paddings, margins },
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  return renderSchema({
    schema: {
      css: {
        ...center,
        ...fluid.column({
          properties: ['width'],
          maxCols: 3,
        }),
        ...paddings.column([0, 1]),
        // ':last-child': {
        //   paddingRight: 0,
        // },
      },
      children: [
        {
          component: 'Image',
          data: image,
          size: cell(2),
          shape: 'circle',
          // fit: 'cover',
          css: {
            border: `solid 1px`,
            // borderRadius: '50%',
            ...margins.h3([0, 'auto', 1]),
          },
        },
        {
          component: 'Short',
          tag: 'h3',
          text: title,
          css: {
            ...paddings.h3([0, 0, 1, 0]),
            // fontSize: `${h5}em`,
          },
        },
        {
          component: 'Long',
          html: text,
          css: {
            // padding: `0 ${h8}em`,
          },
        },
      ],
    },
  })
}

const Services = ({ title, text, list, ...props }) => {
  const {
    cssHelpers: { setFlex },
    defaults: { paddings },
  } = useContext(ThemeContext)
  const { renderSchema } = useContext(RenderSchemaContext)

  const components = [Service]

  return renderSchema({
    schema: {
      component: 'section',
      ...props,
      children: [
        {
          component: 'header',
          css: {
            ...paddings.section([0, 1, 1, 1]),
          },
          children: [
            {
              component: 'Short',
              tag: 'h2',
              text: title,
              css: {
                // fontSize: `${h4}em`,
                // padding: `0 ${paddings.innerSection}`,
              },
            },
            {
              component: 'Long',
              children: text,
              css: {
                // padding: `0 ${paddings.innerSection}`,
                paddingBottom: paddings.sectionDescriptionBottom,
              },
            },
          ],
        },
        {
          component: 'List',
          list,
          components,
          css: {
            ...setFlex({ justify: 'center' }),
            // padding: `0 ${paddings.innerSection}`,
          },
        },
      ],
    },
  })
}

export default Services
